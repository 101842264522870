<template>
  <div>
    <section v-if="isBusy" class="containerGifLoading">
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <section
      v-else
      class="pt-7 md:pt-7 md:pb-20 md:space-x-2 md:justify-center padding-publish-farms"
    >
      <div
        class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 lg:gap-x-3 pb-16"
      >
        <div class="px-8 md:px-0">
          <div
            class="grid grid-cols-2 md:min-h-64 md:bg-agrogo-green-5 md:px-5 mb-4"
            v-if="step && step.id == 1"
          >
            <div class="flex items-center justify-start">
              <a class="font-nexa text-white text-sm text-left lg:text-lg"
                >Departamento</a
              >
            </div>

            <div class="flex justify-end items-center">
              <v-select
                :options="departments"
                v-model="formData.selectedDepart"
                :reduce="(option) => option.id"
                class="style-chooser2 w-full md:max-w-236 text-xs"
                label="name"
              >
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
          </div>
          <div
            class="grid grid-cols-2 md:min-h-64 md:bg-agrogo-green-5 md:px-5 mb-4"
            v-if="step && step.id == 1"
          >
            <div class="flex items-center justify-start">
              <a class="font-nexa text-white text-sm text-left lg:text-lg"
                >Municipio</a
              >
            </div>

            <div class="flex justify-end">
              <v-select
                :options="municipalities"
                v-model="formData.municipality_id"
                :reduce="(option) => option.id"
                class="style-chooser2 w-full md:max-w-236 text-xs"
                label="name"
              >
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
          </div>
          <div class="">
            <div v-for="(item, index) in attributes" :key="index">
              <input-text
                :item="item"
                :value="item.value"
                :index="index"
                @addValue="addValue"
                v-if="
                  item.type_attributes_input.name == 'Text' && item.id !== 5
                "
              />
              <input-number-distance
                :item="item"
                :value="item.value"
                :index="index"
                @addValue="addValue"
                v-if="item.type_attributes_input.name == 'Number'"
              />
            </div>
          </div>
        </div>
        <div>
          <div v-for="(item, index) in attributes" :key="index">
            <location-component
              :item="item"
              @addSubattributes="addSubattributes"
              v-if="item.id_name == 'UBICACION_SATELITAL'"
            />
          </div>
        </div>
      </div>
    </section>
    <div
      class="bg-agrogo-yellow-1 h-14 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button @click="previus()" type="button" class="btn-primary">
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button
        @click="next()"
        :disabled="v$.$invalid || isBusy2"
        type="button"
        class="btn-primary"
      >
        <div class="loader" v-if="isBusy2" />
        <div v-else class="flex">
          Siguiente
          <span
            class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
          ></span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";
import publishFarmMixin from "@/mixins/publishFarmMixin";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
//Vuelidate
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// Components
import InputNumberDistance from "../globals/InputNumberDistance";
import LocationComponent from "../globals/LocationComponent";
import InputText from "../globals/InputText";

export default {
  inject: ["provider"],
  components: {
    InputNumberDistance,
    LocationComponent,
    InputText,
  },
  mixins: [publishFarmMixin, beforeWindowMixin],
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      repository: me.provider.publishFarmRepository,
      repositoryEdit: this.provider.publishFarmEditRepository,
      slug: me.$route.params.slug,
      isBusy: true,
      isBusy2: false,
      departments: [],
      municipalities: [],
      formData: {
        municipality_id: null,
        selectedDepart: null,
        value_attributes: [],
      },
      coords: {
        lat: 6.33732,
        lng: -75.55795,
      },
      formDataCopy: null,
      step2: true,
      dirty: true,
      nextStep: null,
    };
  },
  watch: {
    "formData.selectedDepart": function () {
      const me = this;
      me.loadMunicipies();
    },
    "formData.municipality_id": function () {
      const me = this;
      me.addMunicipy();
    },
  },
  computed: {
    ...mapState("publishFarm", ["step", "formData2", "formData3"]),
  },
  validations: {
    formData: {
      municipality_id: {
        required,
      },
      selectedDepart: {
        required,
      },
      // nearby_municipality_id: {
      //   required,
      // },
    },
    attributeValidations: {
      $each: helpers.forEach({
        value: {
          required,
        },
      }),
    },
  },
  methods: {
    ...mapActions("publishFarm", ["findStep", "addFormData2"]),
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    async next() {
      const me = this;
      if (!me.v$.$invalid) {
        if (
          me.formData3 !== null &&
          (me.step.number2 == 3 || me.step.number2 == 4)
        ) {
          await me.save();
        } else {
          me.findStep(me.step.number2 + 1);
        }
      }
    },
    async loadDepartments() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllDeparts(dataAditional);

        me.departments = data;
      } catch (error) {
        // console.log(error);
      }
    },
    async loadMunicipies() {
      const me = this;
      try {
        me.resetSelect();
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllMuniciByDepart(
          me.formData.selectedDepart,
          dataAditional
        );

        me.municipalities = data.municipalities;
        if (
          me.formDataCopy &&
          me.formDataCopy.selectedDepart == me.formData2.selectedDepart
        ) {
          me.formData.municipality_id = me.formDataCopy.municipality_id;
          // me.formData.nearby_municipality_id =
          //   me.formDataCopy.nearby_municipality_id;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    resetSelect() {
      const me = this;
      me.municipalities = [];
      me.formData.municipality_id = null;
      // me.formData.nearby_municipality_id = null;
    },
    addMunicipy() {
      const me = this;
      me.addFormData2(me.formData);
    },
    async fillFormData() {
      const me = this;
      if (me.formData2) {
        if (me.formData2.selectedDepart) {
          me.formData.selectedDepart = me.formData2.selectedDepart;
        }

        me.formDataCopy = {
          ...me.formData2,
        };
        me.formData.value_attributes = me.formData2.value_attributes;
        me.attributes.map((el) => {
          me.formData.value_attributes.map((x) => {
            if (x.attribute_id == el.id) {
              if (el.subattributes.length > 0) {
                el.subattributes.map((y) => {
                  if (y.id == x.subattribute_id) {
                    y.value = x.value;
                  }
                });
              } else {
                el.value = x.value;
              }
            }
            // Campos requeridos
            me.attributeValidations.map((z) => {
              if (x.attribute_id == z.attribute_id) {
                z.value = x.value;
              }
            });
          });
        });
      }
    },
    // useGeolocation() {
    //   const me = this;
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //       const { latitude, longitude } = position.coords;
    //       me.coords.lat = latitude;
    //       me.coords.lng = longitude;
    //     });
    //   }
    // },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    this.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    const me = this;
    me.nextStep = me.step.number2 + 1;
    window.scroll(0, 0);
    // me.useGeolocation();
    await me.loadAttributes();
    await me.loadDepartments();
    if (me.slug) {
      await me.loadAttributesEdit();
    }
    me.fillFormData();
  },
};
</script>

<style lang="css"></style>
