<template>
  <div class="grid grid-cols-4 md:min-h-64 md:bg-agrogo-green-5 md:px-5">
    <div class="flex items-center">
      <a class="font-nexa text-white text-sm text-left lg:text-base">{{
        item.name
      }}</a>
    </div>
    <div class="col-span-3 flex items-center justify-end">
      <!-- <VueMultiselect
        :options="item.subattributes"
        v-model="selected"
        :close-on-select="false"
        :allow-empty="true"
        :searchable="false"
        :multiple="true"
        :taggable="true"
        label="name"
        placeholder=""
        track-by="name"
        select-label=""
        deselect-label=""
        selected-label=""
      >
        <template #noOptions> Sin opciones </template>
      </VueMultiselect> -->
      <v-select
        :options="item.subattributes"
        v-model="selected"
        :reduce="(option) => option.id.toString()"
        class="style-chooser3 w-full text-right"
        label="name"
        :close-on-select="false"
        multiple
      >
        <template #no-options>Sin opciones</template>
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "values"],
  data() {
    return {
      selected: this.values ? this.values : [],
    };
  },
  watch: {
    selected() {
      const me = this;
      me.$emit("addSubattributesSelectMultiple", me.item.id, me.selected);
    },
    values(newValue) {
      const me = this;
      me.selected = newValue;
    },
  },
};
</script>

<style lang="css">
/* .style-chooser3 .vs__search::placeholder,
.style-chooser3 .vs__dropdown-toggle {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  border-width: 1px !important;
  background-color: transparent !important;
  font-family: "DINPro" !important;
  border-color: #f5ce35 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  font-weight: 400 !important;
  color: #ffff !important;
  width: auto;
}

.style-chooser3 .vs__dropdown-menu {
  background-color: #153e35 !important;
  color: white !important;
}

.style-chooser3 .vs__selected-options {
  left: 0.3rem !important;
}

.style-chooser3 .vs__search {
  background-color: transparent !important;
  border-color: transparent !important;
  border-width: 0 !important;
  padding: 0 !important;
  color: white !important;
}

.style-chooser3 .vs__dropdown-option--highlight {
  background-color: #f5ce35 !important;
  color: white !important;
}

.style-chooser3 .vs__dropdown-toggle {
  color: white !important;
  padding: 0 !important;
}

.style-chooser3 .vs__selected {
  color: white !important;
  text-align: right !important;
  background-color: #f5ce35 !important;
  letter-spacing: 0.1rem;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.style-chooser3 .vs__deselect {
  fill: #2c3e50 !important;
}

.style-chooser3 .vs__open-indicator,
.style-chooser3 .vs__clear {
  fill: white !important;
}

.style-chooser3 .vs__actions {
  background-color: #f5ce35 !important;
  margin-left: 5px !important;
} */
</style>
