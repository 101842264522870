<template>
  <div class="md:min-h-64 md:bg-agrogo-green-5 md:px-5">
    <div
      class="flex flex-col pt-6 md:pt-0 lg:pt-0 h-auto w-auto space-y-3 md:px-0 lg:px-0"
      v-if="item"
    >
      <div class="flex items-center justify-between">
        <span class="font-nexa text-white text-sm text-left lg:text-base"
          >{{ item.name }}
        </span>
        <span
          class="font-nexa text-agrogo-yellow-1 text-sm text-left lg:text-base pr-3"
          >%</span
        >
      </div>
      <div
        class="flex items-center justify-between"
        v-for="(option, index) in item.subattributes"
        :key="index"
      >
        <span
          class="font-nexa text-agrogo-gray-1 text-xs text-left lg:text-base"
          >{{ option.name }}</span
        >
        <input-topography
          :option="option"
          :value="option.value"
          @addSubattributes="addSubattributes"
          @calculateTotal="calculateTotal"
        />
      </div>
      <div class="flex items-center justify-between">
        <div class="font-nexa text-agrogo-red-2 text-sm lg:text-base">
          {{ message }}
        </div>
        <p class="font-dinpro text-agrogo-yellow-1 text-sm lg:text-lg">
          Total {{ total }}%
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import InputTopography from "./InputTopography";
import { mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  components: {
    InputTopography,
  },
  data() {
    return {
      total: 0,
    };
  },
  computed: {
    message() {
      const me = this;
      let isTotal = false;
      let text = "* La sumatoria debe dar 100%";

      if (me.total > 100) {
        text = "* La sumatoria no debe ser superior 100%";
        isTotal = false;
      } else if (me.total == 100) {
        text = "";
        isTotal = true;
      } else {
        text = "* La sumatoria debe dar 100%";
        isTotal = false;
      }
      // me.addIsTopography(visible);
      me.$emit("setTotal", me.item.id, isTotal);
      return text;
    },
  },
  methods: {
    ...mapActions("publishFarm", ["addIsTopography"]),
    addSubattributes(option, value) {
      const me = this;
      me.$emit("addSubattributes", option, value);
    },
    calculateTotal() {
      const me = this;
      me.total = 0;
      me.item.subattributes.map((el) => {
        if (el.value !== "") {
          me.total = me.total + parseInt(el.value);
        }
      });
    },
  },
};
</script>

<style></style>
