<template>
  <div class="grid grid-cols-1">
    <input
      :id="option.name"
      :name="option.name"
      type="text"
      class="input-standard-transparent w-16 md:w-28 lg:w-28 ml-4"
      v-model="attribute"
      :placeholder="option.name"
      @input="format"
    />
  </div>
</template>

<script>
export default {
  emits: ["addSubattributes", "validationHeight"],
  props: {
    value: {
      type: String,
      default: null,
    },
    option: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.$emit("validationHeight");
    },
  },
  methods: {
    format() {
      const me = this;
      let attribute = 0;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      me.attribute = me.attribute.split(".").join("");
      if (isNaN(me.attribute)) me.attribute = 0;
      attribute = formatter.format(me.attribute);
      me.attribute = attribute;
      me.$emit("addSubattributes", me.option, me.attribute);
    },
  },
};
</script>

<style></style>
