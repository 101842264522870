<template>
  <PxHeader :activeSearchDesktop="false" :activeSearchMobile="false" />

  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>

  <section v-else class="bg-agrogo-green-2 sizeMinAllView">
    <div>
      <step-number />
      <step-1 v-if="step && step.id == 0" :isReload="isReload" />
      <step-2 v-else-if="step && step.id == 1" />
      <step-3 v-else-if="step && step.id == 2" />
      <step-4 v-else-if="step && step.id == 3" />
      <step-5 v-else-if="step && step.id == 4" />
      <step-6 v-else-if="step && step.id == 5" />
      <step-7 v-else-if="step && step.id == 6" />
      <step-8 v-else-if="step && step.id == 7" />
      <step-9 v-else-if="step && step.id == 8" />
    </div>
  </section>
</template>

<script>
import PxHeader from "@/components/header/PxHeader";
import StepNumber from "./components/StepNumber";
import { mapActions, mapState } from "vuex";
import { generateHash } from "@/utils";
import validationToken from "@/mixins/validationToken";
import Step1 from "./components/step1/Step1";
import Step2 from "./components/step2/Step2";
import Step3 from "./components/step3/Step3";
import Step4 from "./components/step4/Step4";
import Step5 from "./components/step5/Step5";
import Step6 from "./components/step6/Step6";
import Step7 from "./components/step7/Step7";
import Step8 from "./components/step8/Step8";
import Step9 from "./components/step9/Step9";

export default {
  inject: ["provider"],
  mixins: [validationToken],
  data() {
    const me = this;
    return {
      isBusy: true,
      shouldPrevent: false,
      repository: me.provider.publishFarmRepository,
      repositoryEdit: me.provider.publishFarmEditRepository,
      slug: me.$route.params.slug,
      isReload: false,
    };
  },
  components: {
    StepNumber,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    PxHeader,
  },
  computed: {
    ...mapState("publishFarm", [
      "list_subcategories",
      "step",
      "dataSave",
      "isStep",
    ]),
  },
  methods: {
    ...mapActions("publishFarm", [
      "findStep",
      "addStep",
      "editStep",
      "restoreData",
      "addDataSave",
      "addSubcategory",
      "addFarmSave",
    ]),
    async loadStepGroup() {
      const me = this;
      me.isBusy = true;
      try {
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repository.stepGroup({
          ...dataAditional,
          list_subcategories: me.list_subcategories,
        });
        me.addStep(data);
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    async loadCategories() {
      const me = this;
      try {
        me.isBusy = true;
        const list_subcategories = [];
        const dataAditional = await generateHash();

        const {
          data: { data, property_id },
        } = await me.repositoryEdit.getAll("1", {
          ...dataAditional,
          property_id: me.slug,
        });
        data.subcategories.forEach((el) => {
          if (el.value) {
            list_subcategories.push(el.id);
          }
        });
        me.addFarmSave({
          isFarmSave: true,
          id: property_id,
        });
        me.addSubcategory(list_subcategories);
        me.loadStepGroupEdit();
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        if (!me.slug) me.isBusy = false;
      }
    },
    async loadStepGroupEdit() {
      const me = this;
      me.isBusy = true;
      try {
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repositoryEdit.stepGroup({
          ...dataAditional,
          list_subcategories: me.list_subcategories,
          property_id: me.slug,
        });
        me.addStep(data);
        me.findStep(1);
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    async loadData() {
      const me = this;
      if (me.dataSave) {
        await me.loadStepGroup();
      } else if (me.slug) {
        await me.loadCategories();
      } else {
        me.isBusy = false;
        me.addDataSave(null);
        me.restoreData();
        me.findStep(1);
      }
    },
  },
  beforeRouteLeave() {
    const me = this;
    if (me.isStep) {
      const answer = window.confirm(
        "¿Salir de la página? Si ya pasaste el paso 3 tu finca ha quedado guardada. De lo contrario si abandonas se perdera la información que llevas."
      );
      if (!answer) {
        return false;
      } else {
        me.restoreData();
        if (me.slug) {
          me.slug = null;
          if (me.step && me.step.id == 0) me.isReload = true;
          me.loadData();
        }
      }
    }
  },
  async mounted() {
    const me = this;
    me.loadData();
  },
};
</script>

<style></style>
