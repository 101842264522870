<template>
  <input
    v-model="confirmation"
    id="checkConfirmation"
    name="checkConfirmation"
    :true-value="true"
    :false-value="false"
    type="checkbox"
    class="custom-checkbox-test"
    @change="$emit('checkConfirmation', confirmation)"
  />
</template>

<script>
export default {
  props: {
    confirmationAuthorization: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmation: this.confirmationAuthorization,
    };
  },
  watch: {
    confirmationAuthorization(newValue) {
      const me = this;
      me.confirmation = newValue;
    },
  },
};
</script>

<style></style>
