<template>
  <div>
    <section v-if="isBusy" class="containerGifLoading">
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <section
      v-else
      class="px-8 pt-7 pb-10 md:pt-7 lg:pt-7 md:pb-20 lg:pb-20 md:space-x-2 lg:space-x-2 md:justify-center lg:justify-center md:px-0"
    >
      <div
        class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-6 padding-publish-farms"
      >
        <div v-for="(item, index) in attributes" :key="index">
          <input-number
            :item="item"
            :value="item.value"
            :index="index"
            @addValue="addValue"
            v-if="item.type_attributes_input.name == 'Number'"
          />
          <input-select
            :item="item"
            :value="item.value"
            :index="index"
            @addSubattributesSelect="addSubattributesSelect"
            v-else-if="item.type_attributes_input.name == 'Select'"
          />
          <inputs-height
            :item="item"
            :index="index"
            @setHeight="setHeight"
            @addSubattributes="addSubattributes"
            v-else-if="item.type_attributes_input.name == 'From-To'"
          />
          <topography-component
            :item="item"
            @setTotal="setTotal"
            v-else-if="item.type_attributes_input.name == 'Summation'"
            @addSubattributes="addSubattributes"
          />
          <input-check-multiple
            :item="item"
            :index="index"
            @addSubattributesCheck="addSubattributesCheck"
            v-else-if="item.type_attributes_input.name == 'Checkbox'"
          />
          <input-text-area
            :item="item"
            :value="item.value"
            :index="index"
            @addValue="addValue"
            v-else-if="item.type_attributes_input.name == 'Textarea'"
          />
          <select-multiple
            :item="item"
            :values="item.value"
            @addSubattributesSelectMultiple="addSubattributesSelectMultiple"
            v-else-if="item.type_attributes_input.name == 'Select-multiple'"
          />
        </div>
      </div>
    </section>
    <div
      class="bg-agrogo-yellow-1 h-14 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button
        @click="previus()"
        :disabled="isBusy2"
        type="button"
        class="btn-primary"
      >
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button
        @click="next()"
        :disabled="v$.$invalid || isBusy2"
        type="button"
        class="btn-primary"
      >
        <div class="loader" v-if="isBusy2" />
        <div v-else class="flex">
          Siguiente
          <span
            class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
          ></span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import publishFarmMixin from "@/mixins/publishFarmMixin";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
// Vuelidate
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// components
import InputSelect from "../globals/InputSelect";
import InputsHeight from "../globals/InputsHeight";
import TopographyComponent from "../globals/TopographyComponent";
import InputCheckMultiple from "../globals/InputCheckMultiple";
import InputTextArea from "../globals/InputTextArea";
import InputNumber from "../globals/InputNumber";
import SelectMultiple from "../globals/SelectMultiple";
import xhtml from "caniuse-lite/data/features/xhtml";

export default {
  inject: ["provider"],
  mixins: [publishFarmMixin, beforeWindowMixin],
  components: {
    InputSelect,
    InputsHeight,
    TopographyComponent,
    InputCheckMultiple,
    InputTextArea,
    InputNumber,
    SelectMultiple,
  },
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      repository: me.provider.publishFarmRepository,
      repositoryEdit: me.provider.publishFarmEditRepository,
      slug: me.$route.params.slug,
      isBusy: true,
      isBusy2: false,
      step4: true,
      formData: {
        value_attributes: [],
      },
      isVisible: true,
      isVisible2: false,
      isTotal: false,
      isHeight: false,
      nextStep: null,
      dirty: true,
    };
  },
  validations() {
    const me = this;
    let validation = null;
    let defaultValidation = {
      attributeValidations: {
        $each: helpers.forEach({
          value: {
            required,
          },
        }),
      },
      attributeCheckValidations: {
        $each: helpers.forEach({
          values: {
            required,
          },
        }),
      },
      attributeSelectMultipleValidations: {
        $each: helpers.forEach({
          values: {
            required,
          },
        }),
      },
      attributeSumation: {
        $each: helpers.forEach({
          isTotal: {
            checked(val) {
              return val;
            },
          },
        }),
      },
    };

    if (me.isVisible2) {
      validation = {
        ...defaultValidation,
        isTotal: {
          checked(val) {
            return val;
          },
        },
      };
    } else {
      validation = {
        ...defaultValidation,
      };
    }

    return validation;
  },
  computed: {
    ...mapState("publishFarm", [
      "step",
      "formData2",
      "formData3",
      "formData4",
      "isTopography",
      "dataSave",
      "step",
    ]),
  },
  methods: {
    ...mapActions("publishFarm", ["findStep"]),
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    async next() {
      const me = this;
      if (!me.v$.$invalid) {
        if (
          me.formData2 !== null &&
          me.formData3 !== null &&
          (me.step.number2 == 3 || me.step.number2 == 4)
        ) {
          console.log("entre", me.formData2, me.formData3);
          await me.save();
        } else {
          me.findStep(me.step.number2 + 1);
        }
      }
    },
    fillFormData() {
      const me = this;
      if (me.formData4) {
        me.formData = { ...me.formData4 };
        me.attributes.map((el) => {
          me.formData.value_attributes.map((x) => {
            if (x.attribute_id == el.id) {
              if (x.name && x.name == "selectMultiple") {
                el.value = x.value;
              } else if (
                el.type_attributes_input.name !== "Select" &&
                // el.type_attributes_input.name !== "Select-multiple" &&
                el.subattributes.length > 0
              ) {
                el.subattributes.map((y) => {
                  if (y.id == x.subattribute_id) {
                    y.value = x.value;
                  }
                });
              } else if (el.type_attributes_input.name == "Select") {
                el.value = x.subattribute_id;
              } else {
                el.value = x.value;
              }
            }

            // Campos requeridos
            me.attributeValidations.map((z) => {
              if (x.attribute_id == z.attribute_id) {
                if (
                  el.type_attributes_input.name !== "Select" &&
                  el.type_attributes_input.name !== "Checkbox" &&
                  el.subattributes.length > 0
                ) {
                  el.subattributes.map((y) => {
                    if (
                      y.id == x.subattribute_id &&
                      z.subattribute_id == y.id
                    ) {
                      z.value = x.value;
                    }
                  });
                } else {
                  z.value = x.value;
                }
              }
            });

            // Campos checkbox requeridos
            me.attributeCheckValidations.map((z) => {
              if (el.type_attributes_input.name == "Checkbox") {
                if (x.attribute_id == z.attribute_id) {
                  el.subattributes.map((y) => {
                    if (y.value) {
                      const val = z.values.find(
                        (v) => v.id == x.subattribute_id
                      );

                      if (!val) {
                        z.values.push({
                          value: x.value,
                          id: x.subattribute_id,
                        });
                      }
                    }
                  });
                }
              }
            });

            // Campos select multimple requeridos
            me.attributeSelectMultipleValidations.forEach((z) => {
              if (x.attribute_id == z.id) {
                z.values = el.value;
              }
            });

            // Sumation requerida
            me.attributeSumation.forEach((z) => {
              if (xhtml.attribute_id == z.id) {
                el.isTotal = true;
              }
            });
          });
        });
      }
    },
    // setTotal(value, value2) {
    //   const me = this;
    //   me.isVisible = value;
    //   me.isTotal = value2;
    // },
    setHeight(value) {
      const me = this;
      me.isVisible2 = value;
      me.isHeight = value;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    this.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    const me = this;
    me.nextStep = me.step.number2 + 1;
    window.scroll(0, 0);
    await me.loadAttributes();
    if (me.slug) {
      await me.loadAttributesEdit();
    }
    me.fillFormData();
  },
};
</script>

<style lang="css" scoped></style>
