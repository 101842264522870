<template>
  <div class="flex flex-col space-y-4 md:pt-5 md:pb-8 md:bg-agrogo-green-5">
    <a
      v-if="item"
      class="hidden md:flex lg:flex font-nexa text-white text-base md:items-start md:px-6"
      >{{ item.name }}
    </a>
    <div class="w-full h-auto md:mt-2 md:px-8 flex justify-center">
      <div class="map" id="map"></div>
    </div>
    <a
      v-if="item"
      class="font-nexa text-sm text-white text-left md:hidden px-8 md:px-5"
      >{{ item.name }}
    </a>
    <a class="font-nexa text-xs text-agrogo-gray-2 text-left px-8 md:hidden"
      >Datos para USO INTERNO DE
      <span class="font-nexa font-black text-xs"> AGRO GO</span>
    </a>
    <div
      class="px-8 md:min-h-64 md:px-5"
      v-for="(option, index) in item.subattributes"
      :key="index"
    >
      <input-decimal
        :item="item"
        :value="option.value"
        :option="option"
        @addSubattributes="addSubattributes"
      />
    </div>
    <a
      class="hidden md:flex lg:flex font-nexa text-base text-agrogo-gray-2 px-5"
      >Datos para USO INTERNO DE
      <span class="font-nexa font-black text-base ml-2"> AGRO GO</span>
    </a>
  </div>
</template>

<script>
import InputDecimal from "./InputDecimal";
import { Loader } from "@googlemaps/js-api-loader";

export default {
  components: {
    InputDecimal,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      API_KEY: process.env.VUE_APP_API_KEY_GOOGLE_MAPS,
      coords: {
        lat: null,
        lng: null,
      },
      map: null,
      marker: null,
    };
  },
  methods: {
    addSubattributes(option, value) {
      const me = this;
      me.$emit("addSubattributes", option, value);
      me.addMarker();
    },
    initialMap() {
      const me = this;
      const loader = new Loader({
        apiKey: me.API_KEY,
      });

      me.item.subattributes.forEach((el) => {
        if (el.name == "Latitud") {
          me.coords.lat = el.value;
        } else {
          me.coords.lng = el.value;
        }
      });

      const mapOptions = {
        center: me.coords,
        zoom: 12,
      };

      loader.load().then((google) => {
        me.map = new google.maps.Map(
          document.getElementById("map"),
          mapOptions
        );

        me.marker = new google.maps.Marker({
          position: me.coords,
          map: me.map,
          draggable: true,
          animation: google.maps.Animation.DROP,
        });

        me.marker.addListener("dragend", (evt) => {
          const lat = evt.latLng.lat().toFixed(6);
          const lng = evt.latLng.lng().toFixed(6);
          me.changeInput(lat, lng);
        });
      });
    },
    changeInput(lat, lng) {
      const me = this;
      me.item.subattributes.forEach((el) => {
        if (el.name == "Latitud") {
          me.$emit("addSubattributes", el, parseFloat(lat));
        } else {
          me.$emit("addSubattributes", el, parseFloat(lng));
        }
      });
    },
    addMarker() {
      const me = this;
      me.item.subattributes.forEach((el) => {
        if (el.name == "Latitud") {
          me.coords.lat = el.value;
        } else {
          me.coords.lng = el.value;
        }
      });
      if (me.marker) {
        me.marker.setPosition(me.coords);
        me.map.setCenter(me.coords);
      }
    },
  },
  mounted() {
    const me = this;
    me.initialMap();
  },
};
</script>

<style lang="css">
@media only screen and (max-width: 768px) {
  .map {
    width: 100%;
    height: 200px;
  }
}
@media only screen and (min-width: 768px) {
  .map {
    width: 100%;
    height: 429.25px;
  }
}
</style>
