<template>
  <div class="md:min-h-64 md:bg-agrogo-green-5 md:px-5">
    <div class="flex items-center justify-between">
      <a v-if="item" class="font-nexa text-white text-sm text-left lg:text-base"
        >{{ item.name }} {{ item.unit ? `(${item.unit})` : "" }}</a
      >
      <div class="flex">
        <div v-for="(option, index) in item.subattributes" :key="index">
          <input-height
            :option="option"
            :value="option.value"
            @addSubattributes="addSubattributes"
            @validationHeight="validationHeight"
          />
        </div>
      </div>
    </div>
    <div
      v-if="message"
      class="flex justify-start font-nexa text-agrogo-red-2 text-sm lg:text-base mt-1"
    >
      {{ message }}
    </div>
    <div v-else class="font-nexa text-agrogo-yellow-1 text-sm lg:text-base">
      &nbsp;
    </div>
  </div>
</template>

<script>
import InputHeight from "./InputHeight";
import { mapActions } from "vuex";

export default {
  components: {
    InputHeight,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      message: null,
    };
  },
  methods: {
    ...mapActions("publishFarm", ["addIsHeight"]),
    addSubattributes(option, value) {
      const me = this;
      me.$emit("addSubattributes", option, value);
      me.validationHeight();
    },
    validationHeight() {
      const me = this;
      let isError = false;
      let min,
        min2 = 0;
      let max,
        max2 = 0;
      me.item.subattributes.map((el) => {
        if (el.name == "Min") {
          min = el.value;
        } else {
          max = el.value;
        }
      });

      if (min && min.split(".")) {
        min2 = min.split(".").join("");
      }

      if (max && max.split(".")) {
        max2 = max.split(".").join("");
      }

      if (Number(min2) > Number(max2) && Number(max2) > 0) {
        isError = true;
        me.message = "La altura mínima no puede ser superior a la máxima";
      } else if (Number(max2) < Number(min2) && Number(max2) > 0) {
        isError = true;
        me.message = "La altura máxima no puede ser inferior a la mínima";
      } else {
        isError = false;
        me.message = null;
      }

      me.isVisible = isError;

      me.addIsHeight(me.isVisible);
      me.$emit("setHeight", me.isVisible);
    },
  },
};
</script>

<style></style>
