<template>
  <div>
    <section v-if="isBusy" class="containerGifLoading">
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <section
      v-else
      class="px-14 pt-7 md:pt-7 lg:pt-7 md:pb-20 lg:pb-20 md:space-x-2 lg:space-x-2 md:justify-center lg:justify-center md:px-0"
    >
      <div
        class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-5 pb-5 padding-publish-farms"
      >
        <template v-for="(item, index) in attributes" :key="index">
          <input-number
            :item="item"
            :value="item.value"
            :index="index"
            :classText="'flex'"
            @addValue="addValue"
            v-if="item.type_attributes_input.name == 'Number'"
          />
          <partial-sumation
            :item="item"
            :value="item.value"
            :index="index"
            @addValue="addValue"
            v-else-if="
              item.id_name !== 'PRECIO_HECTAREA' &&
              item.type_attributes_input.name == 'Partial-summation'
            "
          />
          <input-price
            :item="item"
            :value="item.value"
            :index="index"
            @addValue="addValue"
            v-else-if="
              item.id_name !== 'PRECIO_HECTAREA' &&
              item.type_attributes_input.name == 'Price'
            "
          />
          <input-price-calculate
            :item="item"
            :value="item.value"
            v-else-if="item.id_name == 'PRECIO_HECTAREA'"
          />
          <input-radio
            :item="item"
            :value="item.value"
            :index="index"
            @addValueRadio="addValueRadio"
            v-else-if="item.type_attributes_input.name == 'Radio'"
          />
          <input-percentage
            :item="item"
            :value="item.value"
            :index="index"
            :isPercentage="isPercentage"
            @addValuePercentage="addValuePercentage"
            v-else-if="
              item.type_attributes_input.name == 'Percentage' && isPercentage
            "
          />
          <select-seller
            :item="item"
            :value="item.value"
            :confirmationAuthorization="checkAuthorization"
            @addSubattributesSelect="addSubattributesSelect"
            @checkConfirmation="checkConfirmation"
            @checkVisible="checkVisible"
            v-else-if="item.type_attributes_input.name == 'Select'"
          />
        </template>
      </div>
    </section>
    <!-- CONTENEDOR BOTONES NAVEGACION -->
    <div
      class="bg-agrogo-yellow-1 h-14 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button
        @click="previus()"
        :disabled="isBusy2"
        type="button"
        class="btn-primary"
      >
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button
        @click="next()"
        :disabled="v$.$invalid || isBusy2"
        type="button"
        class="btn-primary"
      >
        <div class="loader" v-if="isBusy2" />
        <div v-else class="flex">
          Siguiente
          <span
            class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
          ></span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import publishFarmMixin from "@/mixins/publishFarmMixin";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
// Vuelidate
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
//Components
import InputNumber from "../globals/InputNumber";
import InputPrice from "../globals/InputPrice";
import InputRadio from "../globals/InputRadio";
import InputPercentage from "../globals/InputPercentage";
import InputPriceCalculate from "../globals/InputPriceCalculate";
import SelectSeller from "../globals/SelectSeller";
import PartialSumation from "../globals/PartialSumation";

export default {
  inject: ["provider"],
  components: {
    InputNumber,
    InputPrice,
    InputPriceCalculate,
    InputRadio,
    InputPercentage,
    SelectSeller,
    PartialSumation,
  },
  mixins: [publishFarmMixin, beforeWindowMixin],
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      repository: me.provider.publishFarmRepository,
      repositoryEdit: me.provider.publishFarmEditRepository,
      slug: me.$route.params.slug,
      isBusy: true,
      isBusy2: false,
      formData: {
        value_attributes: [],
      },
      isVisible: false,
      step3: true,
      isPercentage: false,
      nextStep: null,
      dirty: true,
      confirmationAuthorization: false,
    };
  },
  computed: {
    ...mapState("publishFarm", [
      "step",
      "formData2",
      "formData3",
      "checkConfirm",
      "checkAuthorization",
    ]),
  },
  validations() {
    const me = this;
    let defaultValidation = {
      attributeValidations: {
        $each: helpers.forEach({
          value: {
            required,
          },
        }),
      },
    };

    return me.isVisible
      ? {
          ...defaultValidation,
          confirmationAuthorization: {
            checked(val) {
              return val;
            },
          },
        }
      : {
          ...defaultValidation,
        };
  },
  methods: {
    ...mapActions("publishFarm", ["findStep", "updateCheckAuthorization"]),
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    async next() {
      const me = this;
      if (!me.v$.$invalid) {
        if (
          me.formData2 !== null &&
          (me.step.number2 == 3 || me.step.number2 == 4)
        ) {
          console.log("Entre", me.formData2);
          await me.save();
        } else {
          me.findStep(me.step.number2 + 1);
        }
      }
      // me.findStep(3);
    },
    async fillFormData() {
      const me = this;
      if (me.formData3) {
        me.formData = { ...me.formData3 };
        me.attributes.map((el) => {
          me.formData.value_attributes.map((x) => {
            if (x.attribute_id == el.id) {
              if (el.type_attributes_input.name == "Select") {
                el.value = x.subattribute_id;
              } else {
                el.value = x.value;
              }
            }

            // Campos requeridos
            me.attributeValidations.map((z) => {
              if (x.attribute_id == z.attribute_id) {
                z.value = x.value;
              }
            });
          });
        });
        me.confirmationAuthorization = me.checkAuthorization;
      }
    },
    checkConfirmation(value) {
      const me = this;
      me.confirmationAuthorization = value;
      me.updateCheckAuthorization(value);
    },
    checkVisible(isVisible) {
      const me = this;
      me.isVisible = isVisible;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    this.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    const me = this;
    me.nextStep = me.step.number2 + 1;
    window.scroll(0, 0);
    await me.loadAttributes();
    if (me.slug) {
      await me.loadAttributesEdit();
    }
    me.fillFormData();
  },
};
</script>

<style lang="css">
.btn-barter:disabled {
  color: #d4c799 !important;
}
</style>
