<template>
  <div
    class="flex relative overflow-hidden rounded-t-2xl md:rounded-t-30 justify-center"
  >
    <div class="flex w-full h-auto relative" v-if="record">
      <img
        v-if="record.type == 1"
        class="w-full h-auto rounded-t-xl items-center"
        :src="record.urlImg"
        loading="lazy"
        data-src="@/assets/images/loading.gif"
        data-srcset="@/assets/images/loading.gif"
      />
      <video
        v-else
        class="w-full h-auto rounded-t-xl"
        preload="auto"
        poster="@/assets/images/loading.gif"
        controls
        autoplay
        muted
        loop
        playsinline
      >
        <source :src="record.urlVideo" class="w-full h-full" type="video/mp4" />
      </video>
    </div>
    <!-- <div
      class="flex items-center justify-center w-24 h-10 bg-agrogo-green-6 absolute rounded-br-2xl"
    >
      <a class="font-nexa font-regular text-sm text-white">Plus</a>
    </div>
    <div
      class="flex items-center justify-center w-11 h-10 bg-agrogo-yellow-1 absolute rounded-bl-3xl right-0"
    >
      <a class="icon-heart text-base text-white"></a>
    </div> -->
  </div>
  <!-- CARRUSEL IMAGENES -->
  <div class="w-auto h-auto bg-agrogo-green-2 md:bg-agrogo-white md:py-2">
    <div class="items-center px-2 py-2 overflow-x-scroll flex space-x-2">
      <div
        v-for="(item, index) in records"
        :key="index"
        :id="'card' + item.id"
        class="card-carousel w-12 h-12 lg:w-16 lg:h-16"
        @click.prevent="findRecord(item)"
      >
        <img class="w-full h-full" :src="item.urlImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["findRecord"],
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    findRecord(item) {
      const me = this;
      const card = document.querySelectorAll(".card-carousel");
      const cardId = document.getElementById("card" + item.id);

      card.forEach((el) => {
        if (el.classList[5]) el.classList.remove("active-card-img");
      });

      if (cardId) {
        if (cardId.classList[5]) {
          cardId.classList.remove("active-card-img");
        } else {
          cardId.classList.add("active-card-img");
        }
      }
      me.$emit("findRecord", item);
    },
  },
  mounted() {
    const me = this;
    const cardId = document.getElementById("card" + me.record.id);
    cardId.classList.add("active-card-img");
  },
};
</script>

<style lang="css">
.card-carousel.active-card-img {
  border: 3px solid #f5ce35 !important;
}
</style>
