<template>
  <div class="bg-agrogo-yellow-1 h-20 flex md:h-24" v-if="step">
    <div
      class="w-1/3 md:w-2/5 lg:w-2/5 flex items-center justify-end mb-3 pr-3 pl-2 md:pr-4 lg:pr-6 md:justify-end lg:justify-end"
    >
      <span
        class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
        >{{ step.number }}</span
      >
    </div>
    <div class="flex flex-col w-2/3 md:w-3/5 lg:w-3/5 mr-8 justify-center">
      <div class="flex items-star justify-star pb-3">
        <a
          class="font-nexa font-normal text-base text-agrogo-green-2 md:text-2xl"
        >
          <span class="font-nexa" v-html="step.name" />
        </a>
      </div>
      <div
        class="flex flex-row space-x-2 md:space-x-5 lg:space-x-6 md:text-base"
      >
        <span
          class="icon-punto"
          :class="{ 'active-punto': step.id == item.id }"
          v-for="(item, index) in steps"
          :key="index"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("publishFarm", ["steps", "step"]),
  },
};
</script>

<style lang="css">
.icon-punto {
  color: white !important;
}
.active-punto {
  color: #00754a !important;
}
</style>
