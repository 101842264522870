<template>
  <div class="md:min-h-64 md:bg-agrogo-green-5 md:px-5">
    <div class="flex justify-start mb-4">
      <a v-if="item" class="font-nexa text-white text-sm text-left lg:text-base"
        >{{ item.name }} {{ item.unit ? `(${item.unit})` : "" }}
      </a>
    </div>
    <textarea
      :id="item.id_name"
      :name="item.id_name"
      v-model="attribute"
      type="text"
      class="text-xs w-full text-white bg-transparent font-dinpro font-normal placeholder-gray-400 border-2 rounded-b-2xl rounded-r-2xl border-white text-left min-h-123 pl-2 pr-2 md:min-h-167 md:max-w-418"
      placeholder=""
      @input="$emit('addValue', item.id, attribute)"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.attribute = newValue;
    },
  },
};
</script>

<style></style>
