<template>
  <div>
    <section v-if="isBusy" class="containerGifLoading">
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <section v-else class="px-3 py-6 h-auto flex justify-center md:py-12">
      <div>
        <div
          v-if="farmSave && farmSave.isFarmSave"
          class="font-nexa text-agrogo-yellow-1 text-sm lg:text-base mb-4"
        >
          Tu finca ha sido guardada, no es posible modificar el tipo de finca.
        </div>
        <div
          class="grid grid-cols-3 lg:grid-cols-3 md:grid-cols-3 gap-x-4 gap-y-5 md:gap-x-28 md:gap-y-12"
        >
          <div
            class="card"
            :id="'card' + item.id"
            v-for="(item, index) in category.subcategories"
            :key="index"
            @click.prevent="addRemove(item.id)"
          >
            <span
              class="text-5xl text-agrogo-gray-1 mb-2 md:text-7xl md:mb-6"
              :class="item.icon"
            ></span>
            <a
              class="font-nexa text-agrogo-gray-1 text-sm mb-2 md:text-lg md:mb-5"
              >{{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </section>
    <div
      class="bg-agrogo-yellow-1 h-14 flex items-center justify-center space-x-4"
    >
      <button
        @click="next()"
        type="button"
        class="btn-primary"
        :disabled="v$.$invalid"
      >
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";
import validationToken from "@/mixins/validationToken";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";

export default {
  inject: ["provider"],
  mixins: [validationToken, beforeWindowMixin],
  props: {
    isReload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      selected: [],
      category: {},
      repository: this.provider.publishFarmRepository,
      isBusy: true,
      dirty: true,
    };
  },
  watch: {
    isReload() {
      this.selected = [];
    },
  },
  validations() {
    return {
      selected: {
        required,
      },
    };
  },
  computed: {
    ...mapState("publishFarm", ["list_subcategories", "farmSave"]),
    ...mapState("preLaunch", ["isPreLaunch"]),
  },
  methods: {
    ...mapActions("publishFarm", ["addSubcategory", "findStep", "addStep"]),
    async next() {
      const me = this;
      if (!me.v$.$invalid) {
        me.addSubcategory(me.selected);
        try {
          me.isBusy = true;
          const dataAditional = await generateHash();

          const {
            data: { data },
          } = await me.repository.stepGroup({
            ...dataAditional,
            list_subcategories: me.selected,
          });
          me.addStep(data);
          me.findStep(2);
        } catch (error) {
          const code = error.response.status;
          if (code == 401) {
            me.validationExpiredToken();
          }
        } finally {
          me.isBusy = false;
        }
      }
    },
    addRemove(id) {
      const me = this;
      if (me.farmSave && !me.farmSave.isFarmSave) {
        const cardId = document.getElementById("card" + id);
        if (cardId) {
          if (cardId.classList[1]) {
            cardId.classList.remove("active-card");
            me.selected.forEach((el, index) => {
              if (el == id) me.selected.splice(index, 1);
            });
          } else {
            me.selected.push(id);
            cardId.classList.add("active-card");
          }
        }
      }
    },
    loadSelected() {
      const me = this;
      if (me.list_subcategories) {
        me.selected = me.list_subcategories;

        me.selected.forEach((el) => {
          const cardId = document.getElementById("card" + el);
          cardId.classList.add("active-card");
        });
      }
    },
    async loadCategories() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repository.getAll("1", dataAditional);
        me.category = data;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    this.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    const me = this;
    window.scroll(0, 0);
    await me.loadCategories();
    me.loadSelected();
  },
};
</script>

<style lang="css" scoped></style>
